<template>
  <v-card>
    <v-card-title>Search User</v-card-title>
    <v-card-title>
      <v-text-field
        v-model="searchCriteria.userId"
        label="Enter User Id"
        single-line
        hide-details
        v-validate="'required'"
        data-vv-name="user id"
        :error-messages="errors.collect('user id')"
      ></v-text-field>
      <v-btn color="primary" @click="searchRespondent">Search</v-btn>
    </v-card-title>

    <v-data-table :headers="headers" :items="user">
    </v-data-table>
  </v-card>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";

const service = new RestResource();

export default {
  data() {
    return {
      search: "",
      searchCriteria: {},
      headers: [
        { text: "userId", value: "_id", sortable: true },
        { text: "Name", value: "name", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "Mobile Number", value: "mobileNumber", sortable: false }
      ],
      user: [],
    };
  },

  methods: {

    searchRespondent() {
      this.$setLoader();
      this.user = []
      service.searchUser(this.searchCriteria).then(r => {
        this.user.push(r.data)
        this.$disableLoader();
      });
    },
  }
};
</script>